<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { clientsService } from '../../../../services/clients.service';
import Multiselect from "vue-multiselect";

import {
    required, email
} from "vuelidate/lib/validators";
import VsToast from '@vuesimple/vs-toast';
import { rolesService } from '../../../../services/role.service';
/**
 * Blank page component
 */
export default {
    page: {
        title: "Administrateurs",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Multiselect },
    data() {
        return {
            admins: [],
            items: [],
            roles: [],
            title: "Administrateurs",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "created",
            sortDesc: true,
            fields: [
                 { key: "nom", label: 'Nom', sortable: true },
                { key: "prenom", label: 'Prénom', sortable: true },
                { key: "email", label: "Email", sortable: true },
                { key: "phone", label: "Téléphone", sortable: true },
                // { key: "adresse", label: "Adresse", sortable: true },
                { key: "roles", label: "Roles", sortable: false },
                { key: "state", label: "Etat", sortable: true },
                { key: 'options', sortable: false, label: 'Options' }
            ],
            form: {
                nom: "",
                prenom: "",
                email: "",
                phone: "",
                password: "",
                username: "",
                role: [],
                adresse: "",
                typeId: 2
            },
            submitted: false,
            showForm: false,
            selectedRoles: null
        };
    },
    validations: {
        form: {
            nom: { required },
            prenom: { required },
            email: { required, email },
            phone: { required },
            password: { required },
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.admins.length;
        }
    },
    mounted() {
        this.getAdmins();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getAdmins() {
            this.admins = (await clientsService.getAdmin()).filter(admin => admin.roles.some(role => role.name != 'ROLE_USER'));
            this.roles = (await rolesService.get()).filter(e => !e.name.toLowerCase().includes('super'));
        },
        async formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                if (this.selectedRoles.length == 0) {
                    VsToast.show({
                        title: 'Veuillez selectionner un role',
                        message: '',
                        variant: 'warning',
                    });
                    return;
                }
                this.form.role.push(this.selectedRoles.id);
                this.form.username = this.form.email;

                // this.selectedRoles.forEach(element => {
                //     this.form.roles.push(element.id);
                // });
                const rep = await clientsService.create(this.form);
                //console.log(rep);
                if (rep >= 400) {
                    VsToast.show({
                        title: 'Veuillez ressayers',
                        message: 'Une erreur s\'est produite',
                        variant: 'error',
                    })
                } else {

                    VsToast.show({
                        title: 'Enregistrement effectué',
                        message: '',
                        variant: 'success',
                    });
                    this.admins = await clientsService.getAdmin();
                    this.submitted = false; this.showForm = false;
                    this.form.nom = ''; this.selectedRoles = null; this.form.prenom = ''; this.form.phone = ''; this.form.password = ''; this.form.email = ''; this.form.adresse = '';
                }

            }
        },
        async desactivate(id) {
            const resp = await clientsService.desactive(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Compte désactivé',
                    message: '',
                    variant: 'success',
                });
                this.getAdmins();
            }
        },
        async activate(id) {
            const resp = await clientsService.active(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Compte activé',
                    message: '',
                    variant: 'success',
                });
                this.getAdmins();
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!showForm" class="col-md-8">
                <button @click="showForm = true" class="btn btn-success">Ajouter</button>
            </div>
        </div>
        <form v-if="showForm" class="needs-validation mb-2" @submit.prevent="formSubmit">
            <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Nom</label>
                <input
                    id="validationCustom01"
                    v-model="form.nom"
                    type="text"
                    class="form-control"
                    placeholder="Nom"
                    value="Mark"
                    :class="{
                        'is-invalid': submitted && $v.form.nom.$error,
                    }"
                />
                <div
                    v-if="submitted && $v.form.nom.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.form.nom.required"
                    >Champs requis.</span
                    >
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Prénom</label>
                <input
                    id="validationCustom01"
                    v-model="form.prenom"
                    type="text"
                    class="form-control"
                    placeholder="Prénoms"
                    value="Mark"
                    :class="{
                        'is-invalid': submitted && $v.form.prenom.$error,
                    }"
                />
                <div
                    v-if="submitted && $v.form.prenom.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.form.prenom.required"
                    >Champs requis.</span
                    >
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Email / Nom d'utilisateur</label>
                <input
                    id="validationCustom01"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    value="Mark"
                    :class="{
                        'is-invalid': submitted && $v.form.email.$error,
                    }"
                />
                <div
                    v-if="submitted && $v.form.email.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.form.email.required"
                    >Champs requis.</span
                    >
                    <span v-if="!$v.form.email.email"
                      >Entrer une email valide.</span
                    >
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Téléphone</label>
                <input
                    id="validationCustom01"
                    v-model="form.phone"
                    type="tel"
                    v-mask="'## ## ## ##'"
                    class="form-control"
                    placeholder="Téléphone"
                    value="Mark"
                    :class="{
                        'is-invalid': submitted && $v.form.phone.$error,
                    }"
                />
                <div
                    v-if="submitted && $v.form.phone.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.form.phone.required"
                    >Champs requis.</span
                    >
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Mot de passe</label>
                <input
                    id="validationCustom01"
                    v-model="form.password"
                    type="text"
                    class="form-control"
                    placeholder="Mot de passe"
                    value="Mark"
                    :class="{
                        'is-invalid': submitted && $v.form.password.$error,
                    }"
                />
                <div
                    v-if="submitted && $v.form.password.$error"
                    class="invalid-feedback"
                >
                    <span v-if="!$v.form.password.required"
                    >Champs requis.</span
                    >
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Adresse (Optionel)</label>
                <input
                    id="validationCustom01"
                    v-model="form.adresse"
                    type="text"
                    class="form-control"
                    placeholder="Adresse"
                    value="Mark"
                />
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                <label class="form-label" for="validationCustom01">Roles</label>
                <multiselect v-model="selectedRoles" track-by="id" :options="roles" label="name" :multiple="false"></multiselect>
                
                </div>
            </div>
            </div>
            
            <button class="btn btn-primary" type="submit">Enregistrer</button>
            <button @click="showForm = false; form.nom = ''; selectedRoles = []; form.prenom = '', this.form.phone = '', form.password = '', form.email = '', form.adresse = '', submitted = false" class="btn btn-secondary" style="margin-left: 3px;" type="submit">Annuler</button>
        </form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="admins" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            
                                <template v-slot:cell(roles)="r">
                                    <span
                                        style="margin-left: 3px;"
                                        v-bind:key="index"
                                        v-for="(item, index) of r.item.roles"
                                            class="badge"
                                            :class="{
                                                'bg-purple': true,
                                            }">{{ item.name }}</span>
                                </template>
                                <template v-slot:cell(state)="r">
                                    <span style="margin-right: 3px;"
                                        :class="r.item.state ? 'badge bg-success' : 'badge bg-danger'"
                                        > {{ r.item.state ? 'Actif' : 'Désactivé' }}</span>
                                </template>
                                 <template v-slot:cell(options)="r">
                                    <!-- <button v-if="!r.item.activated " @click="showUpdate(r.item)" title="Mettre à jour" class="btn btn-warning btn-sm" style="margin-right: 5px;"><i class="fa fa-edit"></i></button> -->
                                    <button v-if="r.item.state " @click="desactivate(r.item.id)" title="Désactiver" class="btn btn-danger btn-sm"><i class="fa fa-lock"></i></button>
                                    <button v-if="!r.item.state " @click="activate(r.item.id)" title="Activer" class="btn btn-success btn-sm"><i class="fa fa-unlock"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>