import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const clientsService = {
    getClient,
    getAdmin,
    create,
    getOne,
    active,
    desactive

};


async function create(users) {
    try {
        let rets = await axiosInstance.post(`${baseUrl}/users/auth/signup`, users);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function getClient(params) {
    const p = params != null ? params : ''

    try {
        let rets = await axiosInstance.get(`${baseUrl}/users/clients${p}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function getOne(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/users/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function getAdmin() {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/users`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}


async function active(id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/users/${id}/activate`);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function desactive(id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/users/${id}/desactivate`);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}